.animateInputBox {
  animation: animateInputBox 0.5s ease-in-out;
}

@keyframes animateInputBox {
  0% {
    width: 50%;
    top: 25%;
    height: 50%;
  }
  25% {
    height: 25%;
  }
  100% {
    top: 3%;
  }
}
